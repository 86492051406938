package web.android.dev.pe.pages.articles.en

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/articles/en/")
@Composable
fun IndexPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("articles/en/index.md", mapOf("title" to listOf("Articles"), "author" to listOf("Android Dev Perú"), "description" to listOf("Articles written by and for the community."), "language" to listOf("en")))) {
        web.android.dev.pe.components.layout.MarkdownLayout {
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Articles written by and for the community.")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("\"")
                    com.varabyte.kobweb.silk.components.navigation.Link("/articles/talk-recommendations/en") {
                        org.jetbrains.compose.web.dom.Text("Tips for your presentation")
                    }
                    org.jetbrains.compose.web.dom.Text("\" by Android Dev Peru")
                }
            }
        }
    }
}
