package web.android.dev.pe.pages.articles

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/articles/talk-recommendations")
@Composable
fun TalkRecommendationsPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("articles/TalkRecommendations.md", mapOf("title" to listOf("Recomendaciones para tu presentación"), "author" to listOf("Android Dev Perú"), "description" to listOf("Recomendaciones para preparar y dar una charla técnica."), "language" to listOf("es")))) {
        web.android.dev.pe.components.layout.MarkdownLayout {
            org.jetbrains.compose.web.dom.H2(attrs = { id("tl-dr") }) {
                org.jetbrains.compose.web.dom.Text("TL;DR;")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.P {
                        org.jetbrains.compose.web.dom.B {
                            org.jetbrains.compose.web.dom.Text("Sobre tus diapositivas")
                        }
                        org.jetbrains.compose.web.dom.Text(":")
                    }
                    org.jetbrains.compose.web.dom.Ul {
                        org.jetbrains.compose.web.dom.Li {
                            org.jetbrains.compose.web.dom.Text("Sé conciso. Evita el exceso de texto y gráficos.")
                        }
                        org.jetbrains.compose.web.dom.Li {
                            org.jetbrains.compose.web.dom.Text("Usa tamaño de fuente grande. Muy grande.")
                        }
                        org.jetbrains.compose.web.dom.Li {
                            org.jetbrains.compose.web.dom.Text("Contrasta colores. Light > Dark (para presentaciones)")
                        }
                        org.jetbrains.compose.web.dom.Li {
                            org.jetbrains.compose.web.dom.Text("No uses screenshots de bloques gigantes de código, no se ven.")
                        }
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.P {
                        org.jetbrains.compose.web.dom.B {
                            org.jetbrains.compose.web.dom.Text("Sobre tu IDE")
                        }
                        org.jetbrains.compose.web.dom.Text(":")
                    }
                    org.jetbrains.compose.web.dom.Ul {
                        org.jetbrains.compose.web.dom.Li {
                            org.jetbrains.compose.web.dom.Text("Aumenta el tamaño de fuente.")
                        }
                        org.jetbrains.compose.web.dom.Li {
                            org.jetbrains.compose.web.dom.Text("Activa \"Presentation mode\" si es posible.")
                        }
                        org.jetbrains.compose.web.dom.Li {
                            org.jetbrains.compose.web.dom.Text("De preferencia, usa modo ")
                            org.jetbrains.compose.web.dom.B {
                                org.jetbrains.compose.web.dom.Text("light")
                            }
                            org.jetbrains.compose.web.dom.Text(".")
                        }
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.P {
                        org.jetbrains.compose.web.dom.B {
                            org.jetbrains.compose.web.dom.Text("Pídenos feedback")
                        }
                        org.jetbrains.compose.web.dom.Text(". Revisaremos tu presentación y te daremos consejos.")
                    }
                }
            }
            com.varabyte.kobweb.silk.components.display.Callout(type = com.varabyte.kobweb.silk.components.display.CalloutType.TIP, label = null, variant = com.varabyte.kobweb.silk.components.display.CalloutDefaults.Variant) {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text(" En un evento presencial, habrá personas sentadas a varios metros de distancia y viendo la presentación desde distintos ángulos. Por ello, es importante que los elementos visuales sean claros y fáciles de leer.")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("prepara-tu-material") }) {
                org.jetbrains.compose.web.dom.Text("Prepara tu material")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Identifica tu objetivo: ¿Qué quieres que la audiencia aprenda o haga después de tu charla?")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Identifica a tu audiencia: ¿Quiénes son? ¿Qué nivel de conocimiento tienen?")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Crea un borrador de tu charla: ¿Qué temas vas a tratar? ¿En qué orden?")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Use bullet points como resumen.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Puedes ayudarte del ")
                    org.jetbrains.compose.web.dom.Em {
                        org.jetbrains.compose.web.dom.Text("story-telling")
                    }
                    org.jetbrains.compose.web.dom.Text(" y la voz activa. Tu audiencia enterá mejor si narras \"Estuvimos diseñando la arquitectura...\" en vez de \"Se diseñó la arquitectura...\".")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Para una charla técnica:")
                    org.jetbrains.compose.web.dom.Ul {
                        org.jetbrains.compose.web.dom.Li {
                            org.jetbrains.compose.web.dom.Text("Usa tamaño de fuente grande. Muy grande.")
                        }
                        org.jetbrains.compose.web.dom.Li {
                            org.jetbrains.compose.web.dom.Text("Fuentes con ")
                            org.jetbrains.compose.web.dom.Em {
                                org.jetbrains.compose.web.dom.Text("serif")
                            }
                            org.jetbrains.compose.web.dom.Text(" son para papers, no para presentaciones.")
                        }
                        org.jetbrains.compose.web.dom.Li {
                            org.jetbrains.compose.web.dom.Text("Usa gráficos y diagramas simples.")
                        }
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Cierra tu charla con un \"call to action\".")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("practica-tu-presentación") }) {
                org.jetbrains.compose.web.dom.Text("Practica tu presentación")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Cronometra tu charla")
                    }
                    org.jetbrains.compose.web.dom.Text(" teniendo en cuenta el tiempo asignado para tu presentación.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Grábate mientras ensayas")
                    }
                    org.jetbrains.compose.web.dom.Text(" (de preferencia). Puedes ver tus errores y corregirlos.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Prepara preguntas frecuentes")
                    }
                    org.jetbrains.compose.web.dom.Text(". Anticipa las dudas.")
                }
            }
            com.varabyte.kobweb.silk.components.display.Callout(type = com.varabyte.kobweb.silk.components.display.CalloutType.NOTE, label = null, variant = com.varabyte.kobweb.silk.components.display.CalloutDefaults.Variant) {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text(" Recuerda: la audiencia está de tu lado. Están en la sala por ti. Para escucharte. Ellos quieren que lo hagas bien.")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("una-charla-sobre-cómo-dar-una-charla-técnica") }) {
                org.jetbrains.compose.web.dom.Text("\"Una charla sobre cómo dar una charla técnica\"")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Si deseas profundizar en este tema, te recomendamos ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://speakerdeck.com/jflavio11/una-charla-sobre-como-dar-una-charla-tecnica") {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("\"Una charla sobre cómo dar una charla técnica\"")
                    }
                }
                org.jetbrains.compose.web.dom.Text(" de uno de nuestros organizadores, ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://twitter.com/jflavio11") {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Jose Flavio Quispe")
                    }
                }
                org.jetbrains.compose.web.dom.Text(".")
            }
            web.android.dev.pe.pages.articles.components.TalkRecommendationsSlides()
        }
    }
}
