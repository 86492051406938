package web.android.dev.pe.pages.articles

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/articles/")
@Composable
fun IndexPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("articles/index.md", mapOf("title" to listOf("Artículos"), "author" to listOf("Android Dev Perú"), "description" to listOf("Artículos escritos por y para la comunidad."), "language" to listOf("es")))) {
        web.android.dev.pe.components.layout.MarkdownLayout {
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Artículos escritos por y para la comunidad.")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("\"")
                    com.varabyte.kobweb.silk.components.navigation.Link("/articles/talk-recommendations") {
                        org.jetbrains.compose.web.dom.Text("Recomendaciones para tu presentación")
                    }
                    org.jetbrains.compose.web.dom.Text("\" by Android Dev Perú")
                }
            }
        }
    }
}
