package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String

public object StringsEs : Strings {
  override val locale: String = "es"

  override val language_option_es: String = "🇵🇪 Español"

  override val language_option_en: String = "🇺🇸 English"

  override val site_metatag_title: String = "Android Dev Peru"

  override val site_metatag_description: String =
      "Sitio de la comunidad Android Dev Perú. Aquí encontraras eventos, recursos y otras novedades sobre nuestra comunidad de desarrolladores Android."

  override val site_navlink_about_us: String = "Quienes somos"

  override val site_navlink_resources: String = "Recursos"

  override val site_navlink_articles: String = "Artículos"

  override val site_navlink_conference: String = "Conferencia"

  override val site_navlink_subscribe: String = "Suscríbete"

  override val home_header_title: String = "Android Dev Peru"

  override val home_header_caption: String = "Comunidad de Android & Kotlin en Perú y LATAM"

  override val home_quick_links_wsp: String = "Únete en WhatsApp"

  override val home_quick_links_talk: String = "Da una charla"

  override val home_quick_links_linkedin: String = "Síguenos en LinkedIn"

  override val home_highlights_card_title: String = "Contenido destacado"

  override val home_highlights_card_kotlin_conf_title: String = "Kotlin Conf"

  override val home_highlights_card_kotlin_conf_description: String =
      "El pasado Junio nos reunimos presencialmente para el Kotlin Conf - Lima.<br><br>🗓 Fecha: miércoles 26 de junio<br>⏰ Hora: 7PM 🇵🇪 🇨🇴<br>🏢 Local: NTT Data<br>"

  override val home_highlights_card_kotlin_conf_cta: String = "Ver grabación"

  override val home_highlights_card_salary_2023_title: String = "Encuesta salarial 2023"

  override val home_highlights_card_salary_2023_description: String =
      "La transparencia puede ayudar a reducir brechas salariales. El 2023, realizamos encuestas anónimas y recibimos +100 respuestas de mobile devs en LATAM."

  override val home_highlights_card_salary_2023_cta: String = "Mira los resultados"

  override val home_save_the_date: String = "Sábado 19 de Octubre"

  override val home_save_the_date_location: String = "Lima, Peru 🇵🇪"

  override val about_us_title: String = "Comunidad: \"Android Dev Peru\""

  override val about_us_description: String =
      "Somos la comunidad de Android y Kotlin más grande y activa del Perú. A lo largo de los años, hemos organizado decenas de eventos presenciales y virtuales gratuitos.<br><br>Te invitamos a participar como asistente o expositor en nuestros meetups, workshops, y conferencias. <br><br>"

  override val about_organizers_title: String = "Equipo organizador"

  override val about_organizers_caption: String =
      "Ellos son quienes se encargan de mantener la comunidad activa mes a mes."

  override val about_ex_organizers_title: String = "Equipo legacy"

  override val about_ex_organizers_caption: String =
      "Si bien ya no nos acompañan en el equipo, agradecemos mucho el apoyo de nuestros organizadores retirados ❤️"

  override val resources_blog_posts_title: String = "Artículos recientes"

  override val resources_blog_posts_description: String =
      "¿Te perdiste algún evento? No hay problema - ponte al día con las últimas novedades de nuestra comunidad\""

  override val resources_blog_posts_cta: String = "Ver todos los artículos"

  override val resources_blog_posts_caption: String = "Artículos"

  override val resources_blog_posts_meetup_57: String =
      "Meetup #57: Kotlin Multiplatform is Stable!"

  override val resources_blog_posts_meetup_57_desc: String =
      "\"El jueves 26 de noviembre de 2023 nos visitó el GDE Yury Camacho desde Bolivia para hablarnos sobre Kotlin Multiplatform."

  override val resources_blog_posts_devfest_2023: String = "DevFest 2023 - GDG Open"

  override val resources_blog_posts_devfest_2023_desc: String =
      "El 12 de noviembre de 2023, participamos en el DevFest 2023 del GDG Open, en donde elaboramos un taller sobre Jetpack Compose."

  override val resources_blog_posts_bcp: String = "Feria de comunidades en BCP"

  override val resources_blog_posts_bcp_desc: String =
      "El 22 de noviembre tuvimos la oportunidad de participar en la feria de comunidades que organizó el BCP, donde participaron más de 400 personas"

  override val resources_blog_posts_meetup_56: String = "Meetup #56: Bluetooth desde 0"

  override val resources_blog_posts_meetup_56_desc: String =
      "El 30 de octubre, tuvimos una charla virtual!, en nuestro meetup #56 en el cual Hansy Schmitt nos compartió sus conocimientos de Bluetooth."

  override val resources_playlists_title: String = "Playlists"

  override val resources_playlists_description: String =
      "No te pierdas el material exclusivo que la comunidad nos ha compartido a lo largo de lo años."

  override val resources_playlists_cta: String = "Síguenos en Youtube"

  override val resources_playlists_caption: String = "Playlist"

  override val resources_playlists_card_meetups: String = "[LIVE] Meetups"

  override val resources_playlists_card_meetups_desc: String =
      "Revisa las grabaciones de nuestros meetups pasados."

  override val resources_playlists_card_android_workshop: String = "Android desde cero"

  override val resources_playlists_card_android_workshop_desc: String =
      "Workshop gratuito para aprender Android desde cero, hecho por la comunidad."

  override val main_footer_subscribe: String = "Suscríbete"

  override val main_footer_this_web_is: String = "Esta web es "

  override val main_footer_open_source: String = "open source ♥"

  override val conf_title: String = "Android Dev Peru Conf"

  override val conf_meta_title: String = "Android Dev Peru Conf 2024"

  override val conf_meta_description: String =
      "Disfruta de un día lleno de charlas con ponentes internacionales apasionados por Android. Y al igual que todos nuestros eventos - ¡es gratis! 🎉"

  override val conf_navlink_c4p: String = "Call for Speakers"

  override val conf_navlink_sponsor: String = "Sé patrocinador"

  override val conf_navlink_about_us: String = "Quienes somos"

  override val conf_navlink_register: String = "Regístrate"

  override val conf_navlink_code_of_conduct: String = "Código de conducta"

  override val conf_header_time: String = "19 de Octubre. 9am-2pm."

  override val conf_header_location: String = "UPC Monterrico (Lima 🇵🇪)"

  override val conf_header_caption: String =
      "Celebremos juntos los 10 años de la comunidad, y la llegada de Android 15 🎉"

  override val conf_events_title: String = "Sobre el evento"

  override val conf_events_celebrate: String =
      "Celebramos 10 años en comunidad compartiendo nuestro amor por Android."

  override val conf_events_enjoy: String =
      "Disfruta de un día lleno de charlas con ponentes internacionales apasionados por esta plataforma. Y al igual que todos nuestros eventos - "

  override val conf_events_free: String = "¡es gratis! 🎉"

  override val conf_events_register: String = "Regístrate gratis"

  override val conf_c4p_title: String = "Call for Speakers"

  override val conf_c4p_caption: String =
      "Buscamos charlas sobre desarrollo Android, Kotlin, multiplataforma, Gradle, CI/CD, IA/ML, Firebase y más. ¡No dudes en enviarnos tu propuesta!  🎤Call for Speakers"

  override val conf_c4p_cta: String = "Enviar charla"

  override val conf_location_address: String = "Dirección"

  override val conf_location_address_details: String =
      "Prolongación Primavera 2390, Santiago de Surco"

  override val conf_location_place: String = "Lugar"

  override val conf_location_place_details: String = "Auditorio Bancalari - Pabellón H, primer piso"

  override val conf_location_time: String = "Hora de ingreso"

  override val conf_location_time_details: String =
      "Desde las 8am. Tenemos aforo limitado, procura llegar temprano."

  override val conf_location_how_to_get_there: String = "¿Cómo llegar?"

  override val conf_location_how_to_get_there_details: String =
      "1. Ingresa por la entrada de la Av. Primavera"

  override val conf_location_how_to_get_there_details_2: String =
      "2. Dirígete al pabelón H, ubicado a la izquierda"

  override val conf_location_how_to_get_there_details_3: String =
      "3. Baja las escaleras e ingresa al pabellón"

  override val conf_location_how_to_get_there_details_4: String =
      "4. Encontrarás el auditorio en el primer piso"

  override val conf_speakers_title: String = "Ponentes"

  override val conf_speakers_caption: String =
      "Conoce a los ponentes que nos acompañaran en este evento."

  override val conf_speaker_details: String = "Ver detalles"

  override val conf_speaker_interested_register: String = "¿Te interesa? Regístrate"

  override val conf_sponsor_title: String = "Sponsors"

  override val conf_sponsor_caption: String =
      "Un evento de esta magnitud no sería posible sin la ayuda de nuestros sponsors. ¡Muchas gracias!"

  override val conf_sponsor_join: String =
      "¿A ti también te gustaría apoyar a la comunidad y dar a conocer tu empresa a toda nuestra audiencia?"

  override val conf_sponsor_im_interested: String = "Sé un sponsor"

  override val conf_sponsor_plan_gold: String = "Oro"

  override val conf_sponsor_plan_silver: String = "Plata"

  override val conf_sponsor_plan_bronze: String = "Bronce"

  override val conf_volunteers_title: String = "Voluntarios"

  override val conf_volunteers_caption: String =
      "¡Gracias a nuestros voluntarios por hacer posible esta conferencia! "

  override val conf_volunteers_join: String = "Para sumarte al equipo, mándanos un correo a "

  override val conf_agenda_title: String = "Agenda"

  override val conf_sponsorship_title: String = "¿Interesado en ser patrocinador?"

  override val conf_sponsorship_p1: String =
      "En esta ocasión, celebraremos las contribuciones de nuestra comunidad y destacaremos las empresas, las personas y las organizaciones que impulsan el desarrollo de Android en Perú."

  override val conf_sponsorship_p2: String =
      "Queremos celebrar este evento a lo grande. En nuestros 10 años como comunidad, hemos:"

  override val conf_sponsorship_p2_li1: String = "Organizado decenas de eventos:"

  override val conf_sponsorship_p2_li1_1: String =
      "+40 meetups presenciales (asistencia promedio: 30 personas)"

  override val conf_sponsorship_p2_li1_2: String =
      "+20 meetups virtuales (asistencia promedio: 50 personas)"

  override val conf_sponsorship_p2_li1_3: String =
      "3 Workshops presenciales y virtuales (asistencia promedio: 20 personas)"

  override val conf_sponsorship_p2_li1_4: String =
      "Conferencia: Kotlin Everywhere 2019 (asistencia: 80 personas)"

  override val conf_sponsorship_p2_li2: String = "Ampliado nuestra presencia en redes:"

  override val conf_sponsorship_p2_li2_1: String = "WhatsApp: +400 miembros activos"

  override val conf_sponsorship_p2_li2_2: String = "Linkedin: +850 seguidores"

  override val conf_sponsorship_p2_li2_3: String = "Youtube: +850 seguidores"

  override val conf_sponsorship_p2_more_about_us_p1: String =
      "Puedes conocer más sobre la comunidad y el equipo organizador "

  override val conf_sponsorship_p2_more_about_us_p2: String = "en este enlace."

  override val conf_sponsorship_what_we_offer: String = "¿Qué te ofrecemos?"

  override val conf_sponsorship_p3: String =
      "En este Android Dev Peru Conf 2024, esperamos contar con:"

  override val conf_sponsorship_p3_li1: String = "100-140 asistentes presenciales"

  override val conf_sponsorship_p3_li2: String = "50-100 asistentes virtuales (de todo LATAM)"

  override val conf_sponsorship_p3_li3: String =
      "4 charlas de ponentes expertos, nacionales e internacionales"

  override val conf_sponsorship_p3_li4: String = "Cientos de interacciones en todas nuestras redes"

  override val conf_sponsorship_p4: String =
      "Como patrocinador, te ofreceremos distintos espacios en los cuales dar a conocer tu empresa con todo nuestro público. Ofrecemos distintos planes de apoyo, de acuerdo a lo que mejor se acomode a tu empresa."

  override val conf_sponsorship_plan_A: String = "🥇Oro"

  override val conf_sponsorship_plan_B: String = "🥈Plata"

  override val conf_sponsorship_plan_C: String = "🥉Bronce"

  override val conf_sponsorship_plan_benefit_header: String = "Beneficios de cada plan"

  override val conf_sponsorship_plan_benefit_company_logos: String =
      "Logos de la empresa en todos nuestros canales digitales."

  override val conf_sponsorship_plan_benefit_delivery_merch: String =
      "Entrega de merchandising (lapiceros, stickers, camiseta, folletos) proporcionado por la empresa."

  override val conf_sponsorship_plan_benefit_mentions: String =
      "Mención de la empresa en la apertura y cierre del evento."

  override val conf_sponsorship_plan_benefit_logo_size: String = "Tamaño de Logo en nuestras redes."

  override val conf_sponsorship_plan_benefit_company_time_slot: String =
      "Presentación de la empresa durante el evento."

  override val conf_sponsorship_plan_benefit_banner_at_entrance: String =
      "Espacio para un Banner (1x2m) en la entrada del auditorio."

  override val conf_sponsorship_plan_benefit_banner_at_auditorium: String =
      "Espacio para un Banner (1x2m) en el auditorio principal del evento."

  override val conf_sponsorship_plan_benefit_company_speaker: String =
      "Posibilidad de presentar una sesión de 20 min en el evento. (Temática alineada al evento)"

  override val conf_sponsorship_plan_benefit_company_speaker_disclaimer: String =
      "* Sujeto a disponibilidad de agenda. Se dará prioridad a los sponsors que confirmen primero."

  override val conf_sponsorship_plan_benefit_logo_physical_merch: String =
      "Uso del logo de la empresa en polos ( speakers, organizadores y premios)."

  override val conf_sponsorship_plan_benefit_attendance_list: String =
      "Excel con información de las personas registrados al evento, previo consentimiento. (para publicidad y ofertas laborales)."

  override val conf_sponsor_i_want_to_sponsor: String = "Quiero ser sponsor"

  override val conf_sponsor_i_want_to_sponsor_instructions: String =
      "Puedes ponerte en contacto con nosotros por cualquiera de estos medios: "

  override val conf_sponsor_i_want_to_sponsor_option_1: String = "Opción 1: "

  override val conf_sponsor_i_want_to_sponsor_option_2: String = "Opción 2: "

  override val conf_sponsor_i_want_to_sponsor_option_3: String = "Opción 3: "

  override val conf_sponsor_i_want_to_sponsor_email_us_pt1: String = "Escríbenos a "

  override val conf_sponsor_i_want_to_sponsor_email_us_pt2: String =
      " indicando tu empresa y el plan que te interesa."

  override val conf_sponsor_i_want_to_sponsor_fill_form_pt1: String = "Completa este formulario"

  override val conf_sponsor_i_want_to_sponsor_fill_form_pt2: String =
      " y nos pondremos en contacto contigo."

  override val conf_sponsor_i_want_to_sponsor_schedule_meeting_pt1: String =
      "Agenda una reunión con nosotros "

  override val conf_sponsor_i_want_to_sponsor_schedule_meeting_pt2: String =
      " y te aclararemos todas las dudas que tengas."

  override val conf_coc_intro_title: String = "Código de Conducta"

  override val conf_coc_intro_p1: String =
      "Android Dev Perú busca proporcionar una experiencia de conferencia libre de acoso para todos."

  override val conf_coc_intro_p2: String =
      "Cualquier persona que asista (incluidos ponentes, patrocinadores y voluntarios) debe respetar nuestro Código de Conducta. El equipo organizador no tolerará ninguna forma de acoso o discriminación; quien cometa estas acciones será sancionado. Los organizadores harán cumplir este código durante todo el evento. Esperamos la cooperación de todos los participantes para ayudar a asegurar un ambiente seguro para todos. ¡Ayúdanos a hacer de Android Dev Perú Conf 24 una experiencia agradable para todos!"

  override val conf_coc_purpose_title: String = "Propósito de este documento"

  override val conf_coc_purpose_p1: String =
      "En esta conferencia, queremos dar una experiencia libre de acoso para todos aquellos quienes asistan, independientemente de su sexo, orientación sexual, discapacidad, apariencia física, tipo de cuerpo, raza o religión. No toleraremos el acoso de las y los participantes de la conferencia de ninguna forma. El lenguaje y las imágenes sexuales no son apropiadas durante ningún momento de la conferencia; incluyendo charlas, talleres, fiestas, Facebook, X (aka Twitter) y cualquier otra red social."

  override val conf_coc_purpose_p2: String =
      "Los participantes de la conferencia que violen estas reglas podrán ser expulsados del evento a criterio de las y los organizadores del evento."

  override val conf_coc_purpose_p3: String =
      "Los patrocinadores también se encuentran sujetos a nuestra política anti acoso. Estos en particular, no podrán utilizar imágenes, actividades o material sexualizado. El personal de los stands, incluyendo a las y los voluntarios, no podrán utilizar ropa/uniformes/disfraces o cualquier otra cosa que cree un ambiente sexualizado."

  override val conf_coc_report_title: String = "Reportes"

  override val conf_coc_report_p1: String =
      "Si alguien te hace sentir a ti o a alguien más inseguro o inapropiado, por favor repórtalo tan pronto como te sea posible. Si ves u oyes a alguien hacer algo que viole este código de conducta, por favor háznoslo saber. Puedes hacer tu reporte por alguno de los siguientes medios:"

  override val conf_coc_report_card_1: String =
      "Contacta a algún miembro del personal, podrás identificarnos por nuestras playeras con la palabra “organizador”."

  override val conf_coc_report_card_2: String =
      "Mensajes directos por Twitter: nuestros mensajes directos en Twitter se encuentran siempre abiertos."

  override val conf_coc_report_card_3: String =
      "Correo electrónico: Puedes enviarnos un correo electrónico, tardaremos aproximadamente una hora en responderte."

  override val conf_coc_compliance_title: String = "Cumplimiento"

  override val conf_coc_compliance_p1: String =
      "Se espera que a las y los participantes que se les solicite detener cualquier comportamiento de acoso, lo hagan de inmediato."

  override val conf_coc_compliance_p2: String =
      "Cualquiera que se involucre en conductas de acoso las y los organizadores del evento se reservan el derecho a tomar cualquier medida necesaria para mantener el evento con un ambiente acogedor para todas y todos los participantes. Esto incluye advertencias o la expulsión de la conferencia."

  override val conf_coc_compliance_p3: String =
      "Esperamos que todas y todos los participantes sigan estas reglas durante su permanencia en la conferencia, talleres y cualquier evento social relacionado con la conferencia."

  override val conf_coc_harassment_title: String = "Acoso"

  override val conf_coc_harassment_p1: String =
      "Comentarios verbales o escritos que refuercen estructuras sociales de dominación relacionadas al género, identidad y expresión de género, orientación sexual, discapacidad, apariencia física, tipo de cuerpo, raza, edad o religión."

  override val conf_coc_harassment_p2: String = "Imágenes sexuales en espacios públicos."

  override val conf_coc_harassment_p3: String = "Intimidación deliberada, acoso o seguimiento."

  override val conf_coc_harassment_p4: String = "Acoso con fotografías o videos."

  override val conf_coc_harassment_p5: String =
      "Interrupciones constantes durante las conversaciones u otros eventos."

  override val conf_coc_harassment_p6: String = "Contacto físico inapropiado."

  override val conf_coc_harassment_p7: String = "Atención sexual no deseada."

  override val conf_coc_harassment_p8: String =
      "Abogar por, o alentar a alguna de las conductas anteriormente mencionadas."

  override val conf_coc_discrimination_title: String = "Discriminación de la discapacidad"

  override val conf_coc_discrimination_p1: String =
      "Palabras como “demente”, “loco”, “mongol” o “retrasado mental”, son ejemplos de lenguaje capacitista devaluando las personas que tienen alguna discapacidad física o mental. A menudo no se deriva de ningún deseo intencional de ofender, sino de la normalización que le hemos dado a lo que significa ser “normal”. Estas palabras se pueden evitar mediante el uso de descripciones más adecuadas y claras de lo que se quiera comunicar. Para obtener más información acerca de discriminación de la discapacidad y términos de reemplazo, por favor lee esta guía."

  override val conf_coc_need_help_title: String = "¿Necesitas ayuda?"

  override val conf_coc_need_help_p1: String =
      "El staff estará encantado de ayudar a los participantes a sentirse seguros durante la conferencia. ¡Contáctanos! ↓"

  override val conf_coc_need_help_p2: String = "X (Twitter): "

  override val conf_coc_need_help_p3: String = "Correo: "

  override val privacy_policy: String = "Política de privacidad"

  override val privacy_policy_p1: String =
      "Android Dev Peru es una comunidad sin fines de lucro. Los dato que recolectemos no serán utilizado con fines lucrativos."

  override val privacy_policy_p2: String =
      "Este aviso de privacidad le informa qué esperar cuando recopilamos información personal. Se aplica a la información que recopilamos sobre:"

  override val privacy_policy_website: String = "Visitantes a nuestros sitios web"

  override val privacy_policy_website_p1: String =
      "Cuando alguien visita uno de nuestros sitios web, almacenamos información del registro del servidor web, que incluye información como la dirección IP desde la que se conectó, el agente de usuario de su navegador y la página que solicitó. Utilizamos esta información para monitorear la seguridad, el rendimiento y la salud de nuestros sistemas. Todos nuestros sitios web están protegidos por TLS. Esto significa que su conexión a nuestros sitios web está encriptada."

  override val privacy_policy_attendees: String =
      "Personas que se registran y asisten a nuestros eventos"

  override val privacy_policy_attendees_p1: String =
      "Si se ha registrado para nuestros eventos, almacenamos la información personal sobre usted, que fue proporcionada por usted o por alguien de su organización que nos dio permiso para proporcionarnos esta información."

  override val privacy_policy_attendees_p2: String =
      "Esta información incluye su nombre completo, dirección de correo electrónico, y documento de identidad, que requerimos para otorgarle acceso a los eventos. Se puede solicitar información adicional asociada con su boleto, pero no es obligatorio. Esto incluye el cargo, el nombre de la empresa, entre otros. Solicitamos la información adicional opcional porque nos ayuda a comprender mejor el perfil de nuestros asistentes y garantizar que la experiencia general del evento sea relevante para nuestra audiencia. Por ejemplo, solicitamos su cargo para confirmar que incluimos contenido en el programa que sea relevante para su puesto de trabajo. También podemos solicitar sus restricciones y preferencias dietéticas para asegurarnos de pedir la comida adecuada en las cantidades correctas. También podemos usar su información de contacto, incluido el correo electrónico y el número de teléfono, para comunicarnos con usted con información importante relacionada con el evento o en caso de emergencias."

  override val privacy_policy_attendees_p3: String =
      "Previo al evento, es posible que reciba correos electrónicos que incluyan información práctica relacionada con el evento, incluidos horarios de registro, enlaces a eventos sociales adicionales y anuncios de nuevos oradores. Después del evento, es posible que reciba un correo electrónico solicitando comentarios del evento e incluyendo enlaces para ver videos del evento en línea. Los correos electrónicos, tanto antes como después del evento, también pueden contener información sobre próximos eventos comunitarios gratuitos y otra información relevante para los asistentes."

  override val privacy_policy_attendees_p4: String =
      "Cada una de nuestras comunicaciones de marketing le brinda las opciones para cancelar la suscripción o modificar sus preferencias de comunicación. Tiene la opción de cancelar la suscripción a los correos electrónicos de información práctica, pero le recomendamos que mantenga esta suscripción para que podamos enviarle por correo electrónico información práctica importante para usted como asistente al evento."

  override val privacy_policy_attendees_p5: String =
      "Durante el evento, su información personal se imprimirá y se le proporcionará únicamente al personal de seguridad, para que puedan controlar el acceso al evento."

  override val privacy_policy_attendees_p6_pt1: String =
      "Es posible que se tome su fotografía durante el evento y se publique en nuestras redes sociales y canales de marketing. Hágale saber al equipo organizador si no desea que le tomen una foto durante el evento. Si encuentra una foto no deseada de usted en alguno de nuestros canales, envíenos un correo electrónico a "

  override val privacy_policy_attendees_p6_pt2: String =
      " con el enlace a la foto y la eliminaremos."

  override val privacy_policy_speakers: String =
      "Personas que dan charlas en uno de nuestros eventos"

  override val privacy_policy_speakers_p1: String =
      "Cuando habla en uno de nuestros eventos, necesitamos cierta información personal para comunicarnos con usted, organizar viajes y alojamiento o garantizar otros detalles prácticos relacionados con su participación en nuestro evento."

  override val privacy_policy_speakers_p2: String =
      "Esta información incluye su nombre completo, correo electrónico, número de teléfono y país de residencia. Podemos compartir esta información con terceros cuando sea necesario para cumplir con nuestro acuerdo con usted. Por ejemplo, es posible que necesitemos compartir su nombre y correo electrónico con un hotel para reservar una habitación para usted durante el evento. También necesitamos información para publicar y promocionar su charla en nuestro sitio web. Esta información incluye su nombre, nombre de la empresa, foto de perfil, biografía del orador, enlaces a perfiles de redes sociales, enlaces a su(s) blog(s) o sitio(s) web, así como el título y la descripción de la(s) charla(s) y/o taller(es) que realizará en nuestro evento."

  override val privacy_policy_speakers_p3: String =
      "Durante el evento, los asistentes tienen la posibilidad de enviar comentarios en forma de calificaciones, comentarios y preguntas sobre su charla a través de encuestas. Este feedback será monitoreado por los miembros de nuestro equipo durante el evento, y las preguntas durante su charla se le transmitirán en frente de la audiencia al final de la charla si hay tiempo."

  override val privacy_policy_speakers_p4: String =
      "Utilizamos esta información para recopilar comentarios generales sobre el evento, revisar la calidad de los temas y charlas, y utilizarla como input para la planificación de futuras conferencias. No compartimos esta información con terceros."

  override val privacy_policy_speakers_p5_pt1: String =
      "Si la logística lo permite, también podemos grabar su charla en la conferencia y recopilar sus diapositivas para publicarlas en nuestro sitio web. También podemos publicar el video grabado de su charla en "

  override val privacy_policy_speakers_p5_pt2: String = "nuestro canal de YouTube"

  override val privacy_policy_speakers_p5_pt3: String =
      ". También podemos promocionar el video de su charla a través de varios canales de redes sociales."

  override val privacy_policy_speakers_p6: String =
      "Es posible que se tome su fotografía durante el evento y se publique en nuestras redes sociales y canales de marketing. Hágase saber al equipo organizador si no desea que le tomen una foto durante el evento. Si encuentra una foto no deseada de usted en nuestros canales, envíenos un correo electrónico a android@devperu.org con un enlace a la imagen y la eliminaremos."

  override val privacy_policy_sponsors: String = "Personas que son socios o patrocinadores"

  override val privacy_policy_sponsors_p1: String =
      "Cuando firma como socio o patrocinador de nuestros eventos, recopilamos información en forma de datos de facturación para emitir su factura según los términos del acuerdo de asociación. Esta información incluye nombre, correo electrónico, nombre de la empresa, dirección de la empresa, número de teléfono y RUC. En los casos en que no recibimos el pago, podemos enviarle recordatorios de pago o comunicarnos para ayudarlo a finalizar el acuerdo de asociación."

  override val privacy_policy_sponsors_p2: String =
      "También recopilamos los nombres, correos electrónicos y números de teléfono de la persona o personas de contacto que representan a nuestros socios (como el gerente de eventos). Esto es para que podamos cumplir con nuestras obligaciones detalladas en su acuerdo de asociación y mantenerlo informado sobre fechas clave y entregables."

  override val privacy_policy_sponsors_p3_pt1: String =
      "Si su acuerdo de asociación contiene boletos para eventos o pases de expositor, puede consultar la sección "

  override val privacy_policy_sponsors_p3_pt2: String =
      " para obtener información específica relacionada con el registro."

  override val privacy_policy_sponsors_p4_pt1: String =
      "Después de la conferencia, nos comunicaremos con usted para solicitar comentarios adicionales y/o un testimonio, junto con los detalles de los próximos eventos. Puede informarnos en cualquier momento si desea optar por no recibir estas comunicaciones. Para obtener más información, puede consultar la sección "

  override val privacy_policy_sponsors_p4_pt2: String = " de esta política."

  override val privacy_policy_rights: String = "Sus derechos"

  override val privacy_policy_complaints: String = "Quejas o consultas"

  override val privacy_policy_complaints_p1: String =
      "Estamos comprometidos a utilizar las mejores prácticas para garantizar nuestra responsabilidad al recopilar y utilizar información personal, y tomamos muy en serio cualquier queja relacionada con la privacidad de los datos. Si cree que nuestra recopilación y uso de datos es injusto, engañoso o inapropiado, lo alentamos a comunicarse con nosotros utilizando la información de contacto proporcionada en la parte superior de la página."

  override val privacy_policy_access_your_data: String = "Acceso a sus datos"

  override val privacy_policy_access_your_data_p1: String =
      "Tiene derecho a saber qué información almacenamos y procesamos sobre usted, de dónde proviene, para qué la usamos y con quién la compartimos."

  override val privacy_policy_access_your_data_p2: String =
      "Tiene derecho a que se corrija o elimine la información incorrecta o incompleta sobre usted."

  override val privacy_policy_access_your_data_p3: String =
      "Puede ejercer estos derechos poniéndose en contacto con nosotros, y haremos todo lo posible para satisfacer su solicitud de manera oportuna. Para su protección, es posible que necesitemos verificar su identidad antes de responder a su solicitud, como verificar que la dirección de correo electrónico desde la que envía la solicitud coincida con su dirección de correo electrónico que tenemos en nuestros archivos."

  override val privacy_policy_access_your_data_p4: String =
      "En algunos casos, es posible que no podamos eliminar su información si tenemos otra razón legítima que nos impida hacerlo, como la obligación de conservar registros financieros durante un cierto período de tiempo. Póngase en contacto con nosotros si tiene alguna pregunta o inquietud al respecto."
}
