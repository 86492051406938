import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.DeferringHost
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { web.android.dev.pe.pages.HomePage() }
        ctx.router.register("/about-us") { web.android.dev.pe.pages.aboutus.AboutUs() }
        ctx.router.register("/about-us/en") { web.android.dev.pe.pages.aboutus.AboutUs_EN() }
        ctx.router.register("/articles/") { web.android.dev.pe.pages.articles.IndexPage() }
        ctx.router.register("/articles/en/") { web.android.dev.pe.pages.articles.en.IndexPage() }
        ctx.router.register("/articles/talk-recommendations") {
                web.android.dev.pe.pages.articles.TalkRecommendationsPage() }
        ctx.router.register("/articles/talk-recommendations/en") {
                web.android.dev.pe.pages.articles.TalkRecommendations_ENPage() }
        ctx.router.register("/conf") { web.android.dev.pe.pages.conf.ConferenceLanding() }
        ctx.router.register("/conf/agenda") { web.android.dev.pe.pages.conf.subpages.AgendaPage() }
        ctx.router.register("/conf/agenda/en") {
                web.android.dev.pe.pages.conf.subpages.AgendaPage_EN() }
        ctx.router.register("/conf/coc") {
                web.android.dev.pe.pages.conf.subpages.ConfCodeOfConduct() }
        ctx.router.register("/conf/coc/en") {
                web.android.dev.pe.pages.conf.subpages.ConferenceCodeOfConduct_EN() }
        ctx.router.register("/conf/en") { web.android.dev.pe.pages.conf.ConferenceLanding_EN() }
        ctx.router.register("/conf/speakers") {
                web.android.dev.pe.pages.conf.subpages.SpeakersPage() }
        ctx.router.register("/conf/speakers/en") {
                web.android.dev.pe.pages.conf.subpages.SpeakersPage_EN() }
        ctx.router.register("/conf/sponsorship") {
                web.android.dev.pe.pages.conf.subpages.sponsorship.ConfSponsorship() }
        ctx.router.register("/conf/sponsorship/en") {
                web.android.dev.pe.pages.conf.subpages.sponsorship.ConfSponsorship_EN() }
        ctx.router.register("/conf/volunteers") {
                web.android.dev.pe.pages.conf.subpages.VolunteerPage() }
        ctx.router.register("/conf/volunteers/en") {
                web.android.dev.pe.pages.conf.subpages.VolunteerPage_EN() }
        ctx.router.register("/en") { web.android.dev.pe.pages.HomePage_EN() }
        ctx.router.register("/privacy-policy") {
                web.android.dev.pe.pages.privacypolicy.PrivacyPolicy() }
        ctx.router.register("/privacy-policy/en") {
                web.android.dev.pe.pages.privacypolicy.PrivacyPolicy_EN() }
        ctx.router.register("/resources") {
                web.android.dev.pe.pages.resources.RecommendedResources() }
        ctx.router.register("/resources/en") {
                web.android.dev.pe.pages.resources.RecommendedResources_EN() }
        ctx.router.register("/subscribe") { web.android.dev.pe.pages.subscribe.SubscribeForm() }
        ctx.router.register("/subscribe/en") { web.android.dev.pe.pages.subscribe.SubscribeForm_EN()
                }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("conference-site",
                web.android.dev.pe.components.ConferenceSiteStyle)
        ctx.theme.registerStyle("main-site", web.android.dev.pe.components.MainSiteStyle)
        ctx.theme.registerStyle("markdown-container",
                web.android.dev.pe.components.layout.MarkdownContainer)
        ctx.theme.registerStyle("curvy-underline",
                web.android.dev.pe.components.utils.CurvyUnderline)
        ctx.theme.registerStyle("borderless-card-styles_image-container",
                web.android.dev.pe.components.widgets.BorderlessCardStyles.ImageContainer)
        ctx.theme.registerStyle("borderless-card-styles_image",
                web.android.dev.pe.components.widgets.BorderlessCardStyles.Image)
        ctx.theme.registerStyle("borderless-card-styles_card",
                web.android.dev.pe.components.widgets.BorderlessCardStyles.Card)
        ctx.theme.registerStyle("card-styles_container",
                web.android.dev.pe.components.widgets.CardStyles.Container)
        ctx.theme.registerStyle("card-styles_icon",
                web.android.dev.pe.components.widgets.CardStyles.Icon)
        ctx.theme.registerStyle("card-styles_left-section",
                web.android.dev.pe.components.widgets.CardStyles.LeftSection)
        ctx.theme.registerStyle("card-styles_right-section",
                web.android.dev.pe.components.widgets.CardStyles.RightSection)
        ctx.theme.registerStyle("card-styles_cta",
                web.android.dev.pe.components.widgets.CardStyles.Cta)
        ctx.theme.registerStyle("conditional-layout-mobile",
                web.android.dev.pe.components.widgets.ConditionalLayoutMobile)
        ctx.theme.registerStyle("conditional-layout-large",
                web.android.dev.pe.components.widgets.ConditionalLayoutLarge)
        ctx.theme.registerStyle("dialog", web.android.dev.pe.components.widgets.DialogStyle)
        ctx.theme.registerStyle("language-selector",
                web.android.dev.pe.components.widgets.LanguageSelectorStyle)
        ctx.theme.registerStyle("primary-button",
                web.android.dev.pe.components.widgets.PrimaryButtonStyle)
        ctx.theme.registerStyle("theme-switcher",
                web.android.dev.pe.components.widgets.ThemeSwitcherStyle)
        ctx.theme.registerStyle("shared-navbar-styles",
                web.android.dev.pe.components.widgets.navsite.SharedNavbarStyles)
        ctx.theme.registerStyle("navbar-large-item",
                web.android.dev.pe.components.widgets.navsite.NavbarLargeItemStyle)
        ctx.theme.registerStyle("navbar-language-selector",
                web.android.dev.pe.components.widgets.navsite.NavbarLanguageSelectorStyle)
        ctx.theme.registerStyle("navbar-logo-link",
                web.android.dev.pe.components.widgets.navsite.NavbarLogoLinkStyle)
        ctx.theme.registerStyle("nav-link",
                web.android.dev.pe.components.widgets.navsite.NavLinkStyle)
        ctx.theme.registerStyle("logo-and-name",
                web.android.dev.pe.components.widgets.navsite.LogoAndNameStyle)
        ctx.theme.registerStyle("drawer", web.android.dev.pe.components.widgets.navsite.DrawerStyle)
        ctx.theme.registerStyle("drawer-closed",
                web.android.dev.pe.components.widgets.navsite.DrawerClosedStyle)
        ctx.theme.registerStyle("drawer-open",
                web.android.dev.pe.components.widgets.navsite.DrawerOpenStyle)
        ctx.theme.registerStyle("drawer-element",
                web.android.dev.pe.components.widgets.navsite.DrawerElementStyle)
        ctx.theme.registerStyle("navsite-mask",
                web.android.dev.pe.components.widgets.navsite.NavsiteMaskStyle)
        ctx.theme.registerStyle("about-us", web.android.dev.pe.pages.aboutus.AboutUsStyle)
        ctx.theme.registerStyle("conference-grid-section-styles_container",
                web.android.dev.pe.pages.conf.components.layouts.ConferenceGridSectionStyles.container)
        ctx.theme.registerStyle("conference-grid-section-styles_header",
                web.android.dev.pe.pages.conf.components.layouts.ConferenceGridSectionStyles.header)
        ctx.theme.registerStyle("conference-grid-section-styles_content",
                web.android.dev.pe.pages.conf.components.layouts.ConferenceGridSectionStyles.content)
        ctx.theme.registerStyle("conference-section",
                web.android.dev.pe.pages.conf.components.layouts.ConferenceSectionStyle)
        ctx.theme.registerStyle("agenda-styles_card",
                web.android.dev.pe.pages.conf.components.sections.AgendaStyles.card)
        ctx.theme.registerStyle("agenda-styles_heading",
                web.android.dev.pe.pages.conf.components.sections.AgendaStyles.heading)
        ctx.theme.registerStyle("agenda-styles_entries",
                web.android.dev.pe.pages.conf.components.sections.AgendaStyles.entries)
        ctx.theme.registerStyle("agenda-styles_entry",
                web.android.dev.pe.pages.conf.components.sections.AgendaStyles.entry)
        ctx.theme.registerStyle("agenda-styles_entry-time",
                web.android.dev.pe.pages.conf.components.sections.AgendaStyles.entryTime)
        ctx.theme.registerStyle("agenda-styles_entry-title",
                web.android.dev.pe.pages.conf.components.sections.AgendaStyles.entryTitle)
        ctx.theme.registerStyle("agenda-styles_entry-description",
                web.android.dev.pe.pages.conf.components.sections.AgendaStyles.entryDescription)
        ctx.theme.registerStyle("agenda-styles_entry-divider",
                web.android.dev.pe.pages.conf.components.sections.AgendaStyles.entryDivider)
        ctx.theme.registerStyle("conference-header-styles_container",
                web.android.dev.pe.pages.conf.components.sections.ConferenceHeaderStyles.container)
        ctx.theme.registerStyle("conference-header-styles_title",
                web.android.dev.pe.pages.conf.components.sections.ConferenceHeaderStyles.title)
        ctx.theme.registerStyle("conference-header-styles_caption",
                web.android.dev.pe.pages.conf.components.sections.ConferenceHeaderStyles.caption)
        ctx.theme.registerStyle("conference-header-styles_details",
                web.android.dev.pe.pages.conf.components.sections.ConferenceHeaderStyles.details)
        ctx.theme.registerStyle("conference-header-styles_image",
                web.android.dev.pe.pages.conf.components.sections.ConferenceHeaderStyles.image)
        ctx.theme.registerStyle("event-section",
                web.android.dev.pe.pages.conf.components.sections.EventSectionStyle)
        ctx.theme.registerStyle("location-section-styles_container",
                web.android.dev.pe.pages.conf.components.sections.LocationSectionStyles.container)
        ctx.theme.registerStyle("location-section-styles_map",
                web.android.dev.pe.pages.conf.components.sections.LocationSectionStyles.map)
        ctx.theme.registerStyle("location-section-styles_card",
                web.android.dev.pe.pages.conf.components.sections.LocationSectionStyles.card)
        ctx.theme.registerStyle("location-section-styles_picture",
                web.android.dev.pe.pages.conf.components.sections.LocationSectionStyles.picture)
        ctx.theme.registerStyle("location-section-styles_internal-map",
                web.android.dev.pe.pages.conf.components.sections.LocationSectionStyles.internalMap)
        ctx.theme.registerStyle("speakers-section-styles_speaker-title",
                web.android.dev.pe.pages.conf.components.sections.SpeakersSectionStyles.speakerTitle)
        ctx.theme.registerStyle("speakers-section-styles_speaker-card",
                web.android.dev.pe.pages.conf.components.sections.SpeakersSectionStyles.speakerCard)
        ctx.theme.registerStyle("speakers-section-styles_speaker-image-hover",
                web.android.dev.pe.pages.conf.components.sections.SpeakersSectionStyles.speakerImageHover)
        ctx.theme.registerStyle("speakers-section-styles_view-more",
                web.android.dev.pe.pages.conf.components.sections.SpeakersSectionStyles.viewMore)
        ctx.theme.registerStyle("sponsor-section-style_card",
                web.android.dev.pe.pages.conf.components.sections.SponsorSectionStyle.card)
        ctx.theme.registerStyle("volunteer-section-styles_description",
                web.android.dev.pe.pages.conf.components.sections.VolunteerSectionStyles.description)
        ctx.theme.registerStyle("volunteer-section-styles_volunteers",
                web.android.dev.pe.pages.conf.components.sections.VolunteerSectionStyles.volunteers)
        ctx.theme.registerStyle("shiny-card-styles_container",
                web.android.dev.pe.pages.conf.components.widgets.ShinyCardStyles.Container)
        ctx.theme.registerStyle("shiny-card-styles_title",
                web.android.dev.pe.pages.conf.components.widgets.ShinyCardStyles.Title)
        ctx.theme.registerStyle("shiny-card-styles_hint",
                web.android.dev.pe.pages.conf.components.widgets.ShinyCardStyles.Hint)
        ctx.theme.registerStyle("talk-dialog-styles_dialog",
                web.android.dev.pe.pages.conf.components.widgets.TalkDialogStyles.dialog)
        ctx.theme.registerStyle("talk-dialog-styles_container",
                web.android.dev.pe.pages.conf.components.widgets.TalkDialogStyles.container)
        ctx.theme.registerStyle("talk-dialog-styles_speaker-container",
                web.android.dev.pe.pages.conf.components.widgets.TalkDialogStyles.speakerContainer)
        ctx.theme.registerStyle("talk-dialog-styles_speaker-image",
                web.android.dev.pe.pages.conf.components.widgets.TalkDialogStyles.speakerImage)
        ctx.theme.registerStyle("talk-dialog-styles_speaker-info",
                web.android.dev.pe.pages.conf.components.widgets.TalkDialogStyles.speakerInfo)
        ctx.theme.registerStyle("talk-dialog-styles_talk-title",
                web.android.dev.pe.pages.conf.components.widgets.TalkDialogStyles.talkTitle)
        ctx.theme.registerStyle("talk-dialog-styles_talk-description",
                web.android.dev.pe.pages.conf.components.widgets.TalkDialogStyles.talkDescription)
        ctx.theme.registerStyle("conf-code-of-conduct-styles_main-container",
                web.android.dev.pe.pages.conf.subpages.ConfCodeOfConductStyles.MainContainer)
        ctx.theme.registerStyle("conf-code-of-conduct-styles_report-card-container",
                web.android.dev.pe.pages.conf.subpages.ConfCodeOfConductStyles.ReportCardContainer)
        ctx.theme.registerStyle("conf-code-of-conduct-styles_report-card",
                web.android.dev.pe.pages.conf.subpages.ConfCodeOfConductStyles.ReportCard)
        ctx.theme.registerStyle("conf-sponsorship-styles_main-container",
                web.android.dev.pe.pages.conf.subpages.sponsorship.ConfSponsorshipStyles.mainContainer)
        ctx.theme.registerStyle("conf-sponsorship-styles_photos-container",
                web.android.dev.pe.pages.conf.subpages.sponsorship.ConfSponsorshipStyles.photosContainer)
        ctx.theme.registerStyle("conf-sponsorship-styles_contact-options-container",
                web.android.dev.pe.pages.conf.subpages.sponsorship.ConfSponsorshipStyles.ContactOptionsContainer)
        ctx.theme.registerStyle("conf-sponsorship-styles_contact-option",
                web.android.dev.pe.pages.conf.subpages.sponsorship.ConfSponsorshipStyles.ContactOption)
        ctx.theme.registerStyle("sponsorship-plans",
                web.android.dev.pe.pages.conf.subpages.sponsorship.SponsorshipPlansStyle)
        ctx.theme.registerStyle("grid-section-styles_container",
                web.android.dev.pe.pages.home.components.layouts.GridSectionStyles.container)
        ctx.theme.registerStyle("grid-section-styles_header",
                web.android.dev.pe.pages.home.components.layouts.GridSectionStyles.header)
        ctx.theme.registerStyle("grid-section-styles_content",
                web.android.dev.pe.pages.home.components.layouts.GridSectionStyles.content)
        ctx.theme.registerStyle("home-section",
                web.android.dev.pe.pages.home.components.layouts.HomeSectionStyle)
        ctx.theme.registerStyle("alternate-background",
                web.android.dev.pe.pages.home.components.layouts.AlternateBackground)
        ctx.theme.registerStyle("social-media-row",
                web.android.dev.pe.pages.home.components.layouts.SocialMediaRow)
        ctx.theme.registerStyle("highlights-section",
                web.android.dev.pe.pages.home.components.sections.HighlightsSectionStyle)
        ctx.theme.registerStyle("main-footer",
                web.android.dev.pe.pages.home.components.sections.MainFooterStyle)
        ctx.theme.registerStyle("main-footer-extra-links",
                web.android.dev.pe.pages.home.components.sections.MainFooterExtraLinksStyle)
        ctx.theme.registerStyle("quick-links-section",
                web.android.dev.pe.pages.home.components.sections.QuickLinksSectionStyle)
        ctx.theme.registerStyle("quick-link-item",
                web.android.dev.pe.pages.home.components.sections.QuickLinkItemStyle)
        ctx.theme.registerStyle("home-header-styles_container",
                web.android.dev.pe.pages.home.components.sections.HomeHeaderStyles.Container)
        ctx.theme.registerStyle("home-header-styles_title",
                web.android.dev.pe.pages.home.components.sections.HomeHeaderStyles.title)
        ctx.theme.registerStyle("home-header-styles_caption",
                web.android.dev.pe.pages.home.components.sections.HomeHeaderStyles.caption)
        ctx.theme.registerStyle("home-header-styles_details",
                web.android.dev.pe.pages.home.components.sections.HomeHeaderStyles.details)
        ctx.theme.registerStyle("home-header-styles_image",
                web.android.dev.pe.pages.home.components.sections.HomeHeaderStyles.image)
        ctx.theme.registerStyle("home-header-styles_social-media-icon",
                web.android.dev.pe.pages.home.components.sections.HomeHeaderStyles.SocialMediaIcon)
        ctx.theme.registerStyle("privacy-policy-container",
                web.android.dev.pe.pages.privacypolicy.PrivacyPolicyContainer)
        ctx.theme.registerVariant("-filled",
                web.android.dev.pe.components.widgets.FilledPrimaryButtonVariant
        )
        ctx.theme.registerVariant("-outline",
                web.android.dev.pe.components.widgets.OutlinePrimaryButtonVariant
        )
        ctx.theme.registerVariant("-rectangular",
                web.android.dev.pe.components.widgets.RectangularPrimaryButtonVariant
        )
        ctx.theme.registerKeyframes("shiny-card-styles_shadow-pulse",
                web.android.dev.pe.pages.conf.components.widgets.ShinyCardStyles.ShadowPulse)
        web.android.dev.pe.pages.initSiteStyles(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "Android Dev Peru"))
    renderComposable(rootElementId = "root") {
        web.android.dev.pe.AppEntry {
            router.renderActivePage { DeferringHost { it() } }
        }
    }
}
